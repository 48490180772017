import memberReducer from './member';
import stitleReducer from './stitle';
import { combineReducers } from 'redux';

const allReducers =  combineReducers({
    memberinfo: memberReducer,
    stitle: stitleReducer
})


export default allReducers;