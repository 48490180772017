
const memberReducer = (state =[] , action) =>{
    switch(action.type){
        case 'MEMBERINFO':
            //return !state;
            return action.payload;
            default:
                return state;
    }
}


export default memberReducer;