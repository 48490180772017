const stitleReducer = (state =[{onRest:false}] , action) =>{
    switch(action.type){
        case 'STITLE':
            //return !state;
            return action.payload;
            default:
                return state;
    }
}


export default stitleReducer;